export default [
    {
      path: '/vendors/companies/system',
      name: 'vendors-companies-system',
      component: () => import('@/views/vendors/companies/system/VendorCompanyTabs.vue'),
    },
    {
      path: '/vendors/companies/view/:id',
      name: 'vendors-company-view',
      component: () => import('@/views/vendors/companies/company-view/CompanyView.vue'),
    },
    {
      path: '/vendors/companies/contract_view/:id',
      name: 'vendors-companies-contract-view',
      component: () => import('@/views/vendors/companies/contract/preview/Preview.vue'),
    },
    {
      path: '/vendors/contacts/system',
      name: 'vendors-contacts-system',
      component: () => import('@/views/vendors/contacts/system/System.vue'),
    },
    {
      path: '/vendors/contacts/view/:id',
      name: 'vendors-contacts-view',
      component: () => import('@/views/vendors/contacts/contact-view/ContactView.vue'),
    },
    {
      path: '/vendors/applications/',
      name: 'vendors-applications',
      component: () => import('@/views/vendors/applications/system/System.vue'),
    },
    {
      path: '/vendors/individuals/',
      name: 'vendors-individuals',
      component: () => import('@/views/vendors/individuals/system/System.vue'),
    },
  ]
  