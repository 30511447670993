
import Vue from 'vue'

import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

//import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'


import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faWeixin } from '@fortawesome/free-brands-svg-icons'
import { faUser,faEnvelope,faMarsAndVenus,faHouse, faUserTie, faPhone,faFaceSmile, faUserTag, faQuoteRight, faPaperclip, faMapPin, faClock, faHashtag, faLocationDot,faBuildingColumns,faMoneyCheckDollar, faAddressBook, faFileSignature, faHandshake,faPlus, faInbox} from '@fortawesome/free-solid-svg-icons'
import {  } from '@fortawesome/free-regular-svg-icons'
/* add icons to the library */
library.add(faWeixin,
  faInbox,
  faEnvelope,
  faMarsAndVenus,
  faHouse,
  faUserTie,
  faUser,
  faPhone,
  faFaceSmile,
  faUserTag,
  faQuoteRight,
  faPaperclip,
  faMapPin,
  faClock,
  faHashtag,
  faLocationDot,
  faBuildingColumns,
  faMoneyCheckDollar,
  faAddressBook,
  faFileSignature,
  faHandshake,
  faPlus
  )
/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'
import axios from 'axios'

var host = window.location.host

if (host.includes('localhost')) host = 'localhost:5000'

Vue.prototype.$backend = window.location.protocol + '//' + host  + '/api'

Vue.prototype.$axios = axios.create({
  baseURL: Vue.prototype.$backend,
})

Vue.prototype.$axios.defaults.adapter.retryDelay = 1000


Vue.prototype.$axios.interceptors.response.use(
  response => {

    if (response.data.status === "ok" && response.data.msg) {
      Vue.prototype.$toast.success(response.data.msg)
    }
    if (response.data.status === "error" && response.data.msg) {
      Vue.prototype.$toast.error(response.data.msg)
    }
    return response
  },
  error => {
      let errStatus = error.response.status

      if (errStatus == 401) {
        if (error.response.data.detail == "valid_token_required"){
          var userRole = JSON.parse(localStorage.getItem('userData')).role
          Vue.prototype.$toast.error("登录已过期，请重新登录")
          localStorage.removeItem('userData')
          localStorage.removeItem('jwt')
          if (userRole.includes('partner')) {
            window.location = '/login-partner'
          }else{
            window.location = '/login'
          }
          return
        }
        else{
          Vue.prototype.$toast.error("您没有权限执行此操作")
        }
      }
      else if (errStatus == 404) {
        Vue.prototype.$toast.error("请求资源不存在")
      }
      else if (errStatus !== 200) {
        Vue.prototype.$toast.error("请求失败")
        if (error.response.data.detail) Vue.prototype.$toast.error(error.response.data.detail)
      }
    }
)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  //i18n,
  render: h => h(App),
}).$mount('#app')