export default [
    {
      path: '/crm/contacts/system',
      name: 'crm-contacts-system',
      component: () => import('@/views/crm/contacts/system/System.vue'),
    },
    {
      path: '/crm/contacts/view/:id',
      name: 'crm-contacts-view',
      component: () => import('@/views/crm/contacts/contact-view/ContactView.vue'),
    },
    {
      path: '/crm/companies/system',
      name: 'crm-companies-system',
      component: () => import('@/views/crm/companies/system/Companies.vue'),
    },
    {
      path: '/crm/companies/view/:id',
      name: 'crm-company-view',
      component: () => import('@/views/crm/companies/company-view/CompanyView.vue'),
    },
    {
      path: '/crm/companies/contract_view/:id',
      name: 'crm-companies-contract-view',
      component: () => import('@/views/crm/companies/contract/preview/Preview.vue'),
    },
  ]
  