export default [
    {
      path: '/collab/pm/',
      name: 'collab-pm',
      component: () => import('@/views/collab/pm/Panel.vue'),
    },
    {
      path: '/collab/pm/view/:id',
      name: 'collab-pm-view',
      component: () => import('@/views/collab/pm/view/View.vue'),
    },
    {
      path: '/collab/pm/quotation_view/:id',
      name: 'collab-pm-quotation-view',
      component: () => import('@/views/collab/pm/quotation/preview/QuatationView.vue'),
    },
    {
      path: '/collab/pm/sow_view/:id',
      name: 'collab-pm-sow-view',
      component: () => import('@/views/collab/pm/sow/preview/Preview.vue'),
    },
    {
      path: '/collab/pm/vendor_sow_view/:id/:companyId',
      name: 'collab-pm-vendor-sow-view',
      component: () => import('@/views/collab/pm/sow_vendor/preview/Preview.vue'),
    },
    {
      path: '/collab/pm/statement_view/:id',
      name: 'collab-pm-statement-view',
      component: () => import('@/views/collab/pm/statement/preview/StatementView.vue'),
    },
    {
      path: '/collab/pm/vendor_statement_view/:id/:companyId',
      name: 'collab-pm-vendor-statement-view',
      component: () => import('@/views/collab/pm/statement_vendor/preview/StatementView.vue'),
    },
    {
      path: '/collab/pm/launch_mail_view/:id/:sowId',
      name: 'collab-pm-launch-mail-view',
      component: () => import('@/views/collab/pm/mails/preview/LaunchMailView.vue'),
    },
    {
      path: '/collab/pm/statement_mail_view/:id/:statementId',
      name: 'collab-pm-statement-mail-view',
      component: () => import('@/views/collab/pm/mails/preview/StatementMailView.vue'),
    },
    {
      path: '/collab/approvals/',
      name: 'collab-approvals',
      component: () => import('@/views/collab/approvals/Approvals.vue'),
    },
    {
      path: '/collab/approvals/approval_view/:id',
      name: 'collab-approval-view',
      component: () => import('@/views/collab/approvals/approval-view/approval-view/ApprovalView.vue'),
    },
    {
      path: '/collab/approvals/approval_add_new_view/',
      name: 'collab-approvals-add-new-view',
      component: () => import('@/views/collab/approvals/approval-view/add-new-view/ApprovalAddNewView.vue'),
    },
    {
      path: '/collab/approvals/approval_edit_view/:id',
      name: 'collab-approval-edit-view',
      component: () => import('@/views/collab/approvals/approval-view/edit-view/ApprovalEditView.vue'),
    },
    {
      path: '/collab/statements_manage/',
      name: 'collab-statements-manage',
      component: () => import('@/views/collab/statements-manage/StatementsManage.vue'),
    },
    {
      path: '/collab/vendor_statements_manage/',
      name: 'collab-vendor-statements-manage',
      component: () => import('@/views/collab/statements-manage/VendorStatementsManage.vue'),
    }
  ]
  