export default [
    {
      path: '/login-partner',
      name: 'auth-login-partner',
      component: () => import('@/views-partner/auth/LoginPartner.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'auth-register',
      component: () => import('@/views-partner/auth/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/home-partner-individual',
      name: 'home-partner-individual',
      component: () => import('@/views-partner/pages/PartnerHomeIndividual.vue'),
      meta:{
        layout: 'full',
      }
    },
    {
      path: '/home-partner-company',
      name: 'home-partner-company',
      component: () => import('@/views-partner/pages/PartnerHomeCompany.vue'),
      meta:{
        layout: 'full',
      }
    },
    {
      path: '/forgot-password-partner',
      name: 'auth-forgot-password-partner',
      component: () => import('@/views-partner/auth/ForgotPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/reset-password-partner/:code',
      name: 'auth-reset-password-partner',
      component: () => import('@/views-partner/auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/application-partner/',
      name: 'application-partner',
      component: () => import('@/views-partner/pages/application-form/ApplicationForm.vue'),
      meta: {
        resource: 'Auth',
      },
    },
    {
      path: '/application-pending-partner/',
      name: 'application-pending-partner',
      component: () => import('@/views-partner/pages/application-form/Pending.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
      },
    },
    {
      path: '/company/',
      name: 'company-partner',
      component: () => import('@/views-partner/pages/company/CompanyView.vue'),
      meta: {
        resource: 'Auth',
      },
    },
    
    
  ]
  